<style scoped>

.lic_messages {
    padding-top: 20px;
}

.lic_messages li::marker {
    color: green;
}

</style>

<template>
<div class="licenses">
  <v-data-table
    :headers="headers"
    :items="licenses"
    :search="search"
    sort-by="snumber"
    class="elevation-1"
    @dblclick:row="on_dbl_click">

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Licenses</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field readonly
                      v-model="editedItem.snumber"
                      label="Serial number"
                    ></v-text-field>

                    <v-text-field
                      v-model="editedItem.info"
                      label="Description">
                    </v-text-field>

                    <v-text-field readonly
                      v-model="editedItem.bandwidth"
                      label="Bandwidth"
                    ></v-text-field>

                    <v-text-field
                      v-bind:readonly="editedItem.exp_time_permission != 1"
                      v-model="editedItem.expiration_time"
                      label="Expiration time"
                      type="datetime-local"
                    ></v-text-field>

                    <v-text-field
                      v-model="editedItem.passwd"
                      label="Password"
                    ></v-text-field>

                    <v-file-input
                      accept="*" 
                      label="DMI.data" 
                      v-model="dmi_license_file"
                      @change="dmi_license_on_change"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>      
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>

    <template v-slot:no-data>
    </template>
  </v-data-table>
  <div class="lic_messages">
    <ul>
      <li v-for="value in messages" :key="value">{{ value }}</li>
    </ul>
  </div>
</div>
</template>

<script>

import api_client from '../services/api.js';
import moment from 'moment';

export default {
    name: "licenses",

    data: () => ({
        updated_passwd_snumber: '',
        updated_dmi_snumber: '',
        updated_info_snumber: '',
        messages: [],
        search: '',
        dmi_license_file: '',
        dialog: false,
        headers: [
            {
                text: 'Description',
                align: 'start',
                sortable: false,
                value: 'info',
            },
            { text: 'Serial number', value: 'snumber' },
            { text: 'Bandwidth', value: 'bandwidth' },
            { text: 'Expiration time', value: 'expiration_time' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        licenses: [],
        editedIndex: -1,
        editedItemOld : {},
        editedItem: {
            id: 0,
            info: '',
            snumber: '',
            bandwidth: 0,
            expiration_time: 0,
            exp_time_permission: 0,
            passwd: '',
            dmi_license: '',
        },
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New license' : 'Edit license'
        },
    },

    watch: {
        dialog (val) {
            val || this.close()
        },
    },

    created () {
        this.initialize()
    },

    methods: {
        dmi_license_on_change() {
            this.dmi_license_file = event.target.files[0]
        },

        initialize() {
            api_client.get_licences()
            .then(response => {
                this.licenses = response.data;
            })
            .catch(error => {
                console.log(error);
            })
        },

        editItem(item) {
            this.editedIndex = this.licenses.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.expiration_time = moment(item.expiration_time).format('YYYY-MM-DDTHH:mm');
            this.editedItem.passwd = '';
            this.editedItemOld = Object.assign({}, this.editedItem);
            this.dialog = true;
        },

        on_dbl_click(event, data) {
		        this.editItem(data.item);
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.dmi_license_file = '';
            })
        },

        clear_messages() {
            this.messages = [];
        },

        save() {
            if (this.editedIndex > -1) {
                /* update licence's hinfo (dmi_license_file) */
                if (this.dmi_license_file != '' && this.dmi_license_file.size <= 8192) {
                  this.updated_dmi_snumber = this.editedItem.snumber;
                  api_client.update_dmi_license(this.editedItem.id, this.dmi_license_file)
                  .then(() => {
                      this.messages.push('license #' + this.updated_dmi_snumber + ' DMI data has been successfully updated');
                      setTimeout(this.clear_messages, 10000);
                  })
                  .catch(error => {
                      console.log(error);
                  })
                }
                else {
                  this.dmi_license_file = '';
                }

                /* update license's passwd */
                if (this.editedItem.passwd != '') {
                  this.updated_passwd_snumber = this.editedItem.snumber;
                  api_client.update_license_passwd(this.editedItem.id, this.editedItem.passwd)
                  .then(() => {
                      this.messages.push('license #' + this.updated_passwd_snumber + ' password has been successfully updated');
                      setTimeout(this.clear_messages, 10000);
                  })
                  .catch(error => {
                      console.log(error);
                  })
                  this.editedItem.passwd = '';
                  this.licenses[this.editedIndex].passwd = '';
                }

                /* update license's info */
                if (this.editedItem.info != '') {
                  this.updated_info_snumber = this.editedItem.snumber;
                  api_client.update_license_info(this.editedItem.id, this.editedItem.info)
                  .then(() => {
                      this.messages.push('license #' + this.updated_info_snumber + ' description has been successfully updated');
                      setTimeout(this.clear_messages, 10000);
                  })
                  .catch(error => {
                      console.log(error);
                  })
                  this.licenses[this.editedIndex].info = this.editedItem.info;
                }

                /* update expiration time */
                if (this.editedItemOld.expiration_time != this.editedItem.expiration_time) {
                  api_client.update_license_expiration_time(this.editedItem.id, this.editedItem.expiration_time)
                  .catch(error => {
                      console.log(error);
                  })
                  this.editedItemOld.expiration_time = this.editedItem.expiration_time;
                }
            }
            this.close();
        },
    },
};

</script>
