<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="12">
          <img src="/assets/bison_router_logo.svg">
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="this.$store.getters.main_menu.enabled" cols="2">
          <v-card>
            <v-list flat>
              <v-subheader>Main menu</v-subheader>
              <v-list-item-group
                v-model="this.$store.getters.main_menu.selected_item"
                color="primary"
              >
                <v-list-item
                  @click="main_menu_on_click(item.path)"
                  v-for="(item, i) in this.$store.getters.main_menu.items" :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>

        <v-col :cols="this.$store.getters.main_menu_cols">
          <v-main>
            <router-view />
          </v-main>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  created() {
    const auth = localStorage.getItem('auth')
    if (auth) {
      this.$store.commit('SET_USER_DATA', JSON.parse(auth))
    }
  },

  methods: {
    main_menu_on_click(goto_path) {
      this.$router.push({ path: goto_path });
    }
  }
};

</script>
