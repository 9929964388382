<template>
  <validation-observer
    ref="observer"
  >
    <v-card width="400" class="mx-auto mt-5">
      <v-form>
        <v-card-title>
          <h1 class="display-1">Reset password</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email" label="Username" prepend-icon="mdi-account-circle" readonly />

          <v-text-field v-model="passwd"
            :type="showPassword ? 'text' : 'password'"
            label="New password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :disabled="error"
            required
          />
          <v-text-field v-model="passwd2"
            :type="showPassword ? 'text' : 'password'"
            label="Repeat password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :disabled="error"
            required
          />

        </v-card-text>
        <v-divider></v-divider>

        <v-alert v-if="error === true || warn === true"
          border="top"
          color="red lighten-2"
          dark
        >
          {{ error_msg }}
        </v-alert>

        <v-alert v-if="success === true"
          border="top"
          color="green lighten-2"
          dark
        >
          Password has been successfully reset
        </v-alert>

        <v-card-actions>
          <v-btn :disabled="error || success" @click="reset_passwd()" color="info">Reset</v-btn>
          <v-btn @click="login()" color="info">Login</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import api_client from "../services/api.js"

setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
    components: {
        ValidationObserver,
    },

    name: "reset_passwd",

    data() {
        return {
            success: false,
            error: false,
            warn: false,
            error_msg: "",
            otp: "",
            email: "",
            passwd: "",
            passwd2: "",
            showPassword: false,
        };
    },

    created() {
        this.otp = this.$route.params.otp;

        /* request email of the user that owns the one time passwd  */
        api_client.one_time_passwd_user({ one_time_passwd: this.otp })
        .then(response => {
            this.email = response.data.email;
        })
        .catch(error => {
            console.log(error)
            this.error_msg = "Invalid link"
            this.error = true
        })
    },

    methods: {
        submit () {
            this.$refs.observer.validate()
        },

        reset_passwd() {
            if (this.passwd !== this.passwd2) {
                this.error_msg = "passwords should be equal"
                this.warn = true
                return 
            }

            api_client.reset_passwd({ one_time_passwd: this.otp, passwd: this.passwd })
            .then(() => {
                this.success = true
                this.warn = false
                this.error = false
                this.passwd = ""
                this.passwd2 = ""
            })
            .catch(error => {
                this.error_msg = "Reset password failed"
                this.error = true
                console.log(error)
            })
        },

        login() {
            this.$router.push({ path: "/login" });
        },
    }
};
</script>
