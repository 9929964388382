import axios from 'axios';
import router from '../router/index.js';
import store from '../store/index.js';

const api_client = axios.create({
    baseURL: 'https://clients.therouter.net/api',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

api_client.interceptors.response.use(
    response => response,
    error => {
        console.log(error.response)
        if (error.response.status === 401) {
            router.push('/')
            store.dispatch('logout')
        }
        return Promise.reject(error)
    }
);

export default {
    get_licences() {
        return api_client.get('/licenses');
    },

    auth(credentials) {
        return api_client.post("/auth", credentials)
    },

    update_dmi_license(license_id, dmi_license_file) {
        let form_data = new FormData();
        form_data.append('dmi_license_file', dmi_license_file);
        return api_client.post("/dmi_license", form_data,
            { 
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    'license_id': license_id
                }
            })
    },

    update_license_passwd(license_id, passwd) {
        return api_client.post("/license_passwd", {
            license_id: license_id,
            passwd: passwd
        })
    },

    update_license_info(license_id, info) {
        return api_client.post("/license_info", {
            license_id: license_id,
            info: info
        })
    },

    update_license_expiration_time(license_id, expiration_time) {
        return api_client.post("/license_expiration_time", {
            license_id: license_id,
            expiration_time: expiration_time
        })
    },

    logout() {
        return api_client.get("/logout")
    },

    one_time_passwd_user(data) {
        return api_client.post("/users/one_time_passwd_user", data)
    },

    reset_passwd(data) {
        return api_client.post("/users/reset_passwd", data)
    },

    request_passwd_change(data) {
        return api_client.post("/users/request_passwd_change", data)
    },

    current_user_info() {
        return api_client.get("/users/me")
    },

    save_settings(data) {
        return api_client.post("/users/save_settings", data)        
    }    
}
