<template>
  <validation-observer
    ref="observer"
  >
    <v-card>
      <v-form>
        <v-card-title>
          <h1 class="display-1">Settings</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email" label="Username" prepend-icon="mdi-account-circle" readonly />

          <v-text-field v-model="passwd"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
          />

          <v-text-field v-model="passwd2"
            :type="showPassword ? 'text' : 'password'"
            label="Repeat password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
          />

        </v-card-text>
        <v-divider></v-divider>

        <v-alert v-if="error === true"
          border="top"
          color="red lighten-2"
          dark
        >
          {{ error_msg }}
        </v-alert>

        <v-alert v-if="success === true"
          border="top"
          color="green lighten-2"
          dark
        >
          changes successfully saved
        </v-alert>

        <v-card-actions>
          <v-btn @click="save()" color="info">save</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import api_client from "../services/api.js"

setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
    name: "settings",

    data() {
        return {
            success: false,
            error: false,
            error_msg: "",
            otp: "",
            email: "",
            passwd: "",
            passwd2: "",
            showPassword: false,
        };
    },

    created() {
        /* request email of the user that owns the one time passwd  */
        api_client.current_user_info()
        .then(response => {
            this.email = response.data.email;
        })
        .catch(error => {
            console.log(error)
            this.error_msg = "failed to fetch data"
            this.error = true
        })
    },

    methods: {
        submit () {
            this.$refs.observer.validate()
        },

        save() {
            if (this.passwd !== this.passwd2) {
                this.error_msg = "passwords should be equal"
                this.error = true
                return
            }

            api_client.save_settings({ passwd: this.passwd })
            .then(() => {
                this.error = false
                this.success = true
                this.passwd = ""
                this.passwd2 = ""
            })
            .catch(error => {
                this.error_msg = "Reset password failed"
                this.error = true
                console.log(error)
            })
        },
    }
};
</script>
