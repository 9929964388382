import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LoginUser from "../components/LoginUser.vue";
import reset_passwd from "../components/reset_passwd.vue";
import request_passwd_change from "../components/request_passwd_change.vue";
import licenses from "../views/licenses.vue";
import settings from "../views/settings.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: LoginUser
  },
  {
    path: "/reset_passwd/:otp",
    name: "reset_passwd",
    component: reset_passwd
  },
  {
    path: "/request_passwd_change",
    name: "request_passwd_change",
    component: request_passwd_change
  },
  {
    path: "/settings",
    name: "settings",
    component: settings
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        store.dispatch("logout");
        return next("/login")
      },
    }
  },
  {
    path: "/licenses",
    name: "licenses",
    component: licenses
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const public_pages = ["/login", "/reset_passwd", "/request_passwd_change"]
  var auth_required = true
  for (var i = 0; i < public_pages.length; i++)
    if (to.path.includes(public_pages[i])) {
      auth_required = false
      break
    }
  const authenticated = localStorage.getItem("auth")

  // redirect to login page if user is not logged in and trying to access a restricted page
  if (auth_required && !authenticated) {
    return next("/login")
  }

  next();
});

export default router;
