<template>
  <validation-observer
    ref="observer"
  >
    <v-card width="500" class="mx-auto mt-5">
      <v-form>
        <v-card-title>
          <h1 class="display-1">Request password change</h1>
        </v-card-title>
        <v-card-text>
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >        
            <v-text-field v-model="email" :error-messages="errors"
              label="Username" prepend-icon="mdi-account-circle" required />
          </validation-provider>          
        </v-card-text>
        <v-divider></v-divider>

        <v-alert v-if="error === true"
          border="top"
          color="red lighten-2"
          dark
        >
          {{ error_msg }}
        </v-alert>

        <v-alert v-if="success === true"
          border="top"
          color="green lighten-2"
          dark
        >
          Check your email for futher details
        </v-alert>

        <v-card-actions>
          <v-btn @click="request_passwd_change()" color="info">Request</v-btn>
          <v-btn @click="login()" color="info">Login</v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import api_client from "../services/api.js"

setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    name: "request_passwd_change",

    data() {
        return {
            success: false,
            error: false,
            error_msg: "",
            email: "",
        };
    },

    methods: {
        request_passwd_change() {
            api_client.request_passwd_change({ email: this.email })
            .then(() => {
                this.success = true
            })
            .catch(error => {
                this.error_msg = "failed"
                this.error = true                
                console.log(error)
            })
        },

        login() {
            this.$router.push({ path: "/login" });
        },
    }
};
</script>
