<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <v-card width="400" class="mx-auto mt-5">
      <v-form>
        <v-card-title>
          <h1 class="display-1">Login</h1>
        </v-card-title>
        <v-card-text>
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >        
            <v-text-field v-model="email" :error-messages="errors"
              label="Username" prepend-icon="mdi-account-circle" required />
          </validation-provider>

          <v-text-field v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
          />

        </v-card-text>
        <v-divider></v-divider>

        <v-alert v-if="status === 400"
          border="top"
          color="red lighten-2"
          dark
        >
          Invalid email or password.
        </v-alert>

        <v-card-actions>
          <v-btn :disabled="invalid" @click="login()" color="info">Login</v-btn>
          <v-btn @click="forgot_passwd()" color="info">Forgot password?</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  name: "LoginUser",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      status: null
    };
  },
  methods: {
    submit () {
      this.$refs.observer.validate()
    },

    login() {
      this.$store
        .dispatch("login", {
          username: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ path: "/licenses" });
        })
        .catch(err => {
          this.status = err.response.status;
        });
    },

    forgot_passwd() {
      this.$router.push({ path: "/request_passwd_change" });
    }
  }
};
</script>
