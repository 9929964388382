import Vue from "vue";
import Vuex from "vuex";
import api_client from '../services/api.js';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    auth: null,

    /* main menu */
    main_menu: {
      enabled: false,
      selected_item: 1,
      items: [
        { text: 'Licenses', icon: 'mdi-clock', path: "/licenses" },
        { text: 'Settings', icon: 'mdi-flag', path: "/settings" },
        { text: 'Logout', icon: 'mdi-account', path: "/logout"},
      ],
    }
  },
  mutations: {
    SET_USER_DATA(state, auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
      state.auth = auth;
      state.main_menu.enabled = true;
    },
    LOGOUT(state) {
      localStorage.removeItem("auth");
      location.reload();
      state.main_menu.enabled = false;
    }
  },
  actions: {
    login({ commit }, credentials) {
      return api_client.auth(credentials)
      .then(({ data }) => {
          commit("SET_USER_DATA", data);
      });
    },
    logout({ commit }) {
      commit("LOGOUT")
      return api_client.logout()
    }
  },
  modules: {},

  getters: {
    main_menu: state => {
      return state.main_menu
    },

    main_menu_cols: state => {
      if (state.main_menu.enabled) {
        return 10
      }
      else {
        return 12
      }
    }
  }
});
